import './slider.scss';
import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import { Icon } from '@iconify/react';
import Button from '../Button/Button';

function Slider(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const initSlideshow = (position) => {
    position === 'next' ? (index === props.slides.length - 1 ? setIndex(index) : setIndex(index+1)) : (index !== 0 ? setIndex(index-1) : setIndex(index));
  }
  console.log(props.slides[index].images)
  return (
    <>
      <div className='projects-slider'>
        <ul style={{ transform: `translate3d(calc(${index}* (-100% - 10px)), 0, 0)` }}>
          { props.slides.map(slide =>
            <div className='slide'>
              <img src={slide.thumbnail} alt={slide.title} />
              {props.hasModal && (
                <div onClick={() => setIsModalOpen(true)} class='modal-hover'>
                <h3>{slide.title}</h3>
                <Button text='En savoir plus' color='secondary'/>
                </div>
              )}
            </div>
          )}
        </ul>
        <div className='slideshow--buttons'>
          <Button color='secondary' type='btn--round' text={<Icon  icon="grommet-icons:form-previous"/>} onClick={() => initSlideshow('prev')} />
          <Button color='secondary' type='btn--round' text={<Icon  icon="grommet-icons:form-next"/>} onClick={() => initSlideshow('next')} />
        </div>
      </div>

      {props.hasModal &&
        <Modal
          title={props.slides[index].title}
          description={props.slides[index].description}
          date={props.slides[index].date}
          link={props.slides[index].link}
          tags={props.slides[index].technologies}
          images={props.slides[index].images}
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)} />
      }
    </>
  );
}

export default Slider;
