import './modal.scss';
import { Icon } from '@iconify/react';
import Tag from '../Tag/Tag';
import Slider from '../Slider/Slider';

function Modal(props) {
  return (
    <div className={`modal ${props.open ? 'open' : 'close'}`}>
      <div className='modal--content'>
        <button className='btn--transparent' onClick={(() => props.onClose())}><Icon icon="eva:close-fill"/></button>
        <div>
          {props.title && (
            <h3>{props.title}<span class='date'> - {props.date}</span></h3>
          )}
          {props.tags && (
            <div class='technologies'>
              {props.tags.map(tag => (
                <Tag tag={tag} />
              )
            )}
          </div>
          )}
          {props.description && (
            <div class='description'>{props.description}</div>
          )}
          {props.link && (
            <a href={props.link} target="_blank" rel="noreferrer">Voir le projet</a>
          )}
          {props.images && (
            <Slider slides={props.images} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Modal;
