import './contact.scss';
import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';

function Contact() {
  const form = useRef();
  const [isMessageSent, setIsMessageSent] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState({email: '', message: ''});
  const [values, setValues] = useState({email: '', message: ''});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const apiKeys = process.env;
  const handleContactSubmit = (e) => {
    e.preventDefault();
    validate();
    validate() && setError(validate());
    if (Object.keys(validate()).length === 0) {
      setSubmitting(true)
      emailjs.sendForm(apiKeys.REACT_APP_SERVICE_ID, apiKeys.REACT_APP_TEMPLATE_ID, form.current, apiKeys.REACT_APP_USER_ID)
      .then(() => {
        setIsMessageSent(true)
        setIsModalOpen(true)
        setSubmitting(false)
      }, () => {
        setIsMessageSent(false)
      });
    }
  }

  const validate = () => {
    const errors = {}
    if (!values.email || !values.email.toLowerCase().match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi)) {
      errors.email = "Veuillez saisir un email valide."
    }

    if (!values.message) {
      errors.message = "Veuillez saisir un message."
    }

    return errors;
};

  return (
    <>
      <div className='beige-background contact-form' id="contact">
        <div className='limited-content contact--container'>
          <div className='left--container' data-aos='fade-right'>
            <h2>Mon profil vous interesse ? Contactez moi !</h2>
          </div>
          <form ref={form} onSubmit={handleContactSubmit} data-aos='fade-left'>
            <div className='textfield--container'>
              <label>Email</label>
              <input type="text" name="email" onChange={e => setValues({email:e.target.value, message: values.message})}/>
              {error.email && (
                <div className='error-message'>{error.email}</div>
              )}
            </div>
            <div className='textfield--container'>
              <label>Message</label>
              <textarea name="message" onChange={e => setValues({email: values.email, message:e.target.value})}/>
              {error.message && (
                <div className='error-message'>{error.message}</div>
              )}
            </div>
            <Button text='Valider' color='primary' isSubmitting={isSubmitting}/>
          </form>
        </div>
      </div>
      {isMessageSent ?
        <Modal
          description='Votre message a été envoyé, vous recevrez une réponse sous peu.'
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)} />
        : <Modal
            description={<div>Une erreur est survenue, veuillez reessayer plus tard ou bien me contacter par <a href='mailto:elisa.ridel17@gmail.com' className='primary-link'>email</a></div>}
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)} />
      }
    </>
  );
}

export default Contact;
