import './IconLink.scss';
import { Icon } from '@iconify/react';

function IconLink(props) {
  return (
    <a href={props.link} aria-label={props.arialabel} className='icon-link' target='_blank' rel="noreferrer">
      <Icon icon={props.icon} />
    </a>
  );
}

export default IconLink;