import Menu from '../Menu/Menu';
import './header.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from '@iconify/react';
import { profilePicture } from '../index';
import Button from '../Button/Button';
import CV from '../../assets/files/ridel-elisa-cv.pdf'

function Header() {
  return (
    <header className='header green-background' id="about">
      <div className='limited-content header-content'>
        <Menu />
        <div data-aos="fade-zoom-in" className='description'>
          <div>
            <h1>Elisa Ridel</h1>
            <div className='separator'></div> 
            <p class='job'>Développeuse web full-stack</p>
            <p class='hook'>Récemment diplômée d'une licence en informatique, je suis à la recherche d'un emploi en tant que développeuse web !</p>
            <Button text='Voir mon CV' color='btn-link primary' link={true} url={CV}/>
          </div>
        </div>
        <div className='down-icon'><AnchorLink href='#projects'><Icon icon="grommet-icons:form-down"/></AnchorLink></div>
      </div>
      <div className='wave'></div>
    </header>
  );
}

export default Header;
