import './timeline.scss';
import { Icon } from '@iconify/react';
import Card from '../Card/Card';

function Timeline() {
  const experiences = [
    {
      title: 'Alternance à Nodevo',
      description: 'Création et maintenance de sites internet développés en Drupal.',
      date: '2021 - 2022',
      place: 'Chantilly'
    },
    {
      title: 'Licence Informatique (Option développement web)',
      description: 'Apprentissage du Javascript, React, Php, Symfony, Docker. Initiation aux bases d\'UX et UI design.',
      date: '2021 - 2022',
      place: 'Lycée St-Vincent, Senlis'
    },
    {
      title: 'Stage Pretidev',
      description: 'Réalisation de composants en React Typescript pour un site visant à faciliter les rencontres amicales.',
      date: 'Janvier - Fevrier 2021',
      place: 'Vigneux-sur-Seine'
    },
    {
      title: 'BTS Services informatiques aux organisations',
      description: 'Apprentissage du Html5, CSS3, Php, et Symfony.',
      date: '2019-2021',
      place: 'Lycée St-Vincent, Senlis'
    }
  ]

  return (
    <div className='light-green--background experiences' id="experience">
      <h2 className='limited-content'>Mes expériences</h2>
      <div className='experiences--container'>
        {experiences.map(experience => (
          <Card surtitle={`${experience.date} | ${experience.place}`} title={experience.title} description={experience.description}></Card>
        ))}
      </div>
    </div>
  );
}

export default Timeline;
