import './projects.scss';
import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import { Icon } from '@iconify/react';
import { 
  dermaweb1,
  cvv1,
  henatree1,
  henatree2,
  henatree3,
  henatree4,
  stv1,
  stv2,
  stv3,
  stv4,
  modulo1,
  modulo2,
  modulo3,
  modulo4,
  modulo5,
  esport1,
  esport2,
  esport3,
  esport4
} from '../index';
import Button from '../Button/Button';
import Slider from '../Slider/Slider';

function Projects() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const projects = [
    {
      title: 'Dermaweb',
      description: 'Dermaweb est un site web sur lequel j\'ai développé diverses fonctionnalités lors de mon alternance chez Nodevo. C\'est un site d\'informations et d\'échanges professionnels en dermatologie.',
      date: 'Mars - Juin 2022',
      thumbnail: dermaweb1,
      link: 'https://www.dermaweb.com/',
      technologies: [
        'Drupal',
        'Javascript',
        'Php',
        'Scss',
        'Twig'
      ]
    },
    {
      title: 'Conseil des ventes',
      description: 'Le site pour le Conseil des Ventes a été développé dqns le cadre de mon alternance chez Nodevo. C\'est un site d\'information sur le Conseil des ventes, il permet aussi de déposer des réclammations.',
      date: 'Décembre 2021 - Février 2022',
      thumbnail: cvv1,
      link: 'https://www.conseildesventes.fr/',
      technologies: [
        'Drupal',
        'Javascript',
        'Php',
        'Scss',
        'Twig'
      ]
    },
    {
      title: 'Modulo Scout',
      description: 'Ce projet a été réalisé dans le cadre de mes études. C\'est une Rest Api qui vise a faciliter la programmation d\'événements pour l\'association des scouts des Hauts-de-France.',
      date: 'Mai - Juin 2022',
      thumbnail: modulo1,
      link: 'https://github.com/levraimarius/modulo-scout-docker',
      technologies: [
        'React',
        'Symfony',
        'Scss'
      ],
      images: [
        {
          title: "Page de connexion",
          thumbnail: modulo1
        },
        {
          title: "Création d'un événement",
          thumbnail: modulo2
        },
        {
          title: "Agenda",
          thumbnail: modulo3
        },
        {
          title: "Habilitations",
          thumbnail: modulo4
        },
        {
          title: "Ajout d'un utilisateur",
          thumbnail: modulo5
        }
      ],
    },
    {
      title: 'Site internet de paris d\'e-sport',
      description: "Ce projet a été réalisé dans le cadre de mes études. C'est un site internet qui permet a ses utilisateurs de consulter diverses équipes d'e-sport, de voir leurs matchs et de parier sur les matchs en cours et à venir. Les données utilisées viennent de l'Api disponible sur pandascore.",
      date: 'Décembre 2021 - Janvier 2022',
      thumbnail: esport1,
      technologies: [
        'React',
        'Scss'
      ],
      images: [
        {
          title: "Différentes ligues d'un jeu vidéo",
          thumbnail: esport1
        },
        {
          title: "Les différentes matchs d'une ligue",
          thumbnail: esport2
        },
        {
          title: "Les membres d'une ligue",
          thumbnail: esport3
        },
        {
          title: "Tous les matchs",
          thumbnail: esport4
        }
      ]
    },
    {
      title: 'Henatree',
      description: "Ce projet a été réalisé lors de mon stage de BTS. C\'est un site internet qui a pour objectif de faciliter les rencontres entre amis.",
      date: 'Janvier - Fevrier 2021',
      thumbnail: henatree1,
      technologies: [
        'React',
        'Typescript',
        'Css-in-js (styled-components)',
        'Strapi',
      ],
      images: [
        {
          title: "Page d'accueil - Henatree header",
          thumbnail: henatree1
        },
        {
          title: "Page d'accueil - en quoi Henatree consiste ?",
          thumbnail: henatree2
        },
        {
          title: "Page d'accueil - les avis",
          thumbnail: henatree3
        },
        {
          title: "Page d'accueil - comment ça marche ?",
          thumbnail: henatree4
        }
      ]
    },
    {
      title: 'Association Sportive du lycée St-Vincent',
      description: "Ce projet a été réalisé dans le cadre de mes études. C\'est un site internet qui permet au lycée St-Vincent d'organiser des événements et compétitions. Les élèves ont ainsi la possibilité de s'inscrire aux événements pour y participer.",
      date: 'Décembre - Juin 2021',
      thumbnail: stv1,
      technologies: [
        'Symfony',
        'Css',
        'Bootstrap',
      ],
      images: [
        {
          title: "Les derniers événements",
          thumbnail: stv1
        },
        {
          title: "Détail d'un événement",
          thumbnail: stv3
        },
        {
          title: "Formulaire de connexion",
          thumbnail: stv4
        }
      ]
    }
  ]

  const [index, setIndex] = useState(0);
  return (
    <>
      <div className='projects__container beige-background'  id="projects">
        <div className='limited-content'>
          <div className='projects-title' data-aos='fade-right'>
            <h2>Mes projets</h2>
            <p>Les principaux projets sur lesquels j'ai eu l'occasion de travailler ces dernières années.</p>
          </div>
          <Slider slides={projects} hasModal={true}></Slider>
        </div>
      </div>
    </>
  );
}

export default Projects;
