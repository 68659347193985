import './menu.scss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import SocialNetworks from '../SocialNetworks/SocialNetworks';

function Menu() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <button className='mobile menu-icon' onClick={() => setMenuOpen(!isMenuOpen)}><Icon icon='eva:menu-fill' aria-label='Ouvre le menu'/></button>
      <div className={`menu ${isMenuOpen ? 'open' : 'close'}`}>
        <ul>
          <li className='menu-item'><AnchorLink className='secondary-link' href='#about' onClick={() => setMenuOpen(!isMenuOpen)}>A propos</AnchorLink></li>
          <li className='menu-item'><AnchorLink className='secondary-link' href='#projects' onClick={() => setMenuOpen(!isMenuOpen)}>Projets</AnchorLink></li>
          <li className='menu-item'><AnchorLink className='secondary-link' href='#experience' onClick={() => setMenuOpen(!isMenuOpen)}>Experience</AnchorLink></li>
          <li className='menu-item'><AnchorLink className='secondary-link' href='#contact' onClick={() => setMenuOpen(!isMenuOpen)}>Contact</AnchorLink></li>
        </ul>
        <SocialNetworks />
      </div>
    </>
  )
}

export default Menu;