import './card.scss';

function Card(props) {
  return (
    <div className='card' data-aos='fade-up'>
      <div className='card--content'>
        <div className='date'>{props.surtitle}</div>
        <h3>{props.title}</h3>
        <p className='description'>{props.description}</p>
      </div>
    </div>
  );
}

export default Card;