import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Projects from './components/Projects/Projects';
import Timeline from './components/Timeline/Timeline';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import SocialNetworks from './components/SocialNetworks/SocialNetworks';

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <Header></Header>
      <Projects />
      <Timeline />
      <Contact />
      <Footer />
      <SocialNetworks />
    </div>
  );
}

export default App;
