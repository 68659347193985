import './button.scss';

function Button(props) {

  return (
    <>
    {props.link ?
      <a className={props.color} href={props.url} target='_blank'>
        {props.text}
        {props.isSubmitting && (
          <div className="spinner"></div>
        )}
      </a>
      : (
        <button className={`btn ${props.color} ${props.type}`} onClick={props.onClick}>
          {props.text}
          {props.isSubmitting && (
            <div className="spinner"></div>
          )}
        </button>)
    }
    </>
  );
}

export default Button;
