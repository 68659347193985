import IconLink from '../IconLink/IconLink';
import './socialNetworks.scss';

function SocialNetworks() {
  return (
    <div className='social-networks__container'>
      <IconLink icon='icomoon-free:github' link='https://github.com/elisaridel?tab=repositories' arialabel='Lien vers repositories github' />
      <IconLink icon='icomoon-free:linkedin' link='https://www.linkedin.com/in/elisa-ridel-b5735a1b9/?originalSubdomain=fr' arialebl='Lien vers profil linkedin' />
    </div>
  );
}

export default SocialNetworks;